.sectionAddTask {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
}

.sectionItems {
	width: 100%;
	margin: 8px 0;
}

.task {
	width: 95%;
}

.title {
	margin: 20px;
}

.sectionSelect {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.sectionSelect .sectionItems {
	flex: 135px;
}

.sectionImportant {
	display: flex;
	flex-direction: column;
}

.sectionImportant > input {
	height: 25px;
}

.date {
	width: 160px;
	font-size: 1.15em;
}

.errorTask {
	margin: 5px 0 0 0;
	color: tomato;
	animation: animationError 0.5s;
}

@keyframes animationError {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.1);
	}
	40% {
		transform: scale(0.9);
	}
	70% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.btn {
	width: 63%;
	padding: 7px;
	margin: 10px auto;
	font-size: 1em;
}

@media (min-width: 668px) {
	.sectionAddTask {
		position: sticky;
		top: 10px;
		width: 35%;
		height: 450px;
		margin-right: 15px;
	}
}

@media (min-width: 992px) {
	.sectionAddTask {
		width: 22%;
	}
	.sectionSelect .sectionItems {
		flex: 170px;
	}
}
