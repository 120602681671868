.sectionTaskActive,
.sectionTaskDone {
	margin-top: 10px;
	padding: 15px 8px;
	background-color: #03030319;
	border-radius: 20px;
}

.title {
	text-align: center;
}

.info {
	text-align: center;
	animation: animationError 0.5s;
}

@keyframes animationError {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.1);
	}
	40% {
		transform: scale(0.9);
	}
	70% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@media (min-width: 668px) {
	.sectionTaskActive {
		width: 60%;
	}

	.sectionTaskDone {
		width: 60%;
	}
}

@media (min-width: 992px) {
	.sectionTaskActive {
		width: 41%;
	}
	.sectionTaskDone {
		width: 30%;
	}
}
